import React, { useState, Component, useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import CardBlog from "../components/CardBlog";
import ContactsBlock from "../components/ContactsBlock";

import DecorationBlob18 from "../images/decorations/deco-blob-18.svg";
import DecorationBlob3 from "../images/decorations/deco-blob-3.svg";

import ContactsBlockLandingPage from "../components/ContactsBlockLandingPage";
import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import LogoAgentAdvance from "../images/logos/brand/agent-advance.svg";
import LogoThinkpricing from "../images/logos/brand/think-pricing-colored.svg";
import LogoThinkpricingGrey from "../images/logos/brand/grey-thinkpricing.svg";
import LogoKinteract from "../images/logos/brand/grey-kinteract.svg";
import LogoQolo from "../images/logos/brand/qolo-colored.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import ThinkpricingReview from "../images/logos/thinkpricing-review.svg";
import AgentadvanceReview from "../images/logos/agentadvance-review.svg";
import Testimonials from "../components/Testimonials";

import IconCheck from "../images/icons/interface/check.svg";
import IconPointer from "../images/icons/pages/home/pointer.svg";
import IconGlobe from "../images/icons/pages/home/globe.svg";
import IconBulb from "../images/icons/pages/home/bulb.svg";
import IconCross from "../images/icons/interface/cross.svg";
import IconRoad from "../images/icons/theme/tools/road-cone.svg";
import IconShovel from "../images/icons/theme/tools/shovel.svg";

import Divider1 from "../images/dividers/divider-3.svg";
// import Divider2 from "../images/dividers/divider-2.svg";
// import Divider2 from '../images/dividers/divider-2.custom.svg';

import DecorationDots6 from "../images/decorations/deco-dots-6.svg";
import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import DecorationLine6 from "../images/decorations/deco-lines-6.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import QuoteIconGrey from "..//images/icons/theme/text/quote-1-grey.svg";
// import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
import { IndexJsonLd } from "../components/jsonld/IndexJsonLd";
import useInViewPort from "../hooks/useInViewPort";
// import DecorationBlob19 from "../images/decorations/deco-blob-19.svg";


const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?fm=webp&w=400 400w,
${src}?fm=webp&w=800 800w,
${src}?fm=webp&w=1200 1200w,
${src}?fm=webp&w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <source
      srcSet={`${src}?w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <img
      sizes="(max-width: 800px) 100vw, 800px"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      src={`${src}?w=1200`}
      alt={alt || ""}
      className={className || ""}
      style={style || {}}
      loading={lazy ? "lazy" : "eager"}
    />
  </picture>
);


const UxConsultingPage = ({ data, location: { pathname } }) => {
  const [isInViewRef, isInView] = useInViewPort();
  const FeaturedArticlesData = data.featuredArticles.edges;

/*   useMixpanelTracker("43", (mixpanel, formData) => {
    mixpanel.track("Exit intent form submission", {
      name: formData.name,
      email: formData.email,
    });

    mixpanel.people.set({
      $name: formData["name"],
      $email: formData["email"],
    });
  }); */

  return (
    <Layout isMainPage footerBgClass="bg-primary-alt">
      <div className="index-page pillar-page">
        <IndexJsonLd />
        <SectionFirstScreen data={data} />
        <SectionWhyUXConsulting data={data} />
        <SectionOurImpact data={data} />
        <TestimonialBlock data={data} />
        <SectionBlog FeaturedArticlesData={FeaturedArticlesData} />
        <SectionTrustedCompanies />
        <SectionFaq data={data} />
        
        <div ref={isInViewRef}> </div>
        
        {/* <SectionIndustries data={data} /> */}
        {/* <SectionTopUXAgency data={data} /> */}
        {/* <SectionOurBook data={data} /> */}
        
        
      </div>

      {/* <ContactsBlockLandingPage isInViewProp={isInView} /> */}
    </Layout>
  );
};

const SectionFirstScreen = ({ data }) => {
  const ImageHero = data.ImageHero.childImageSharp.gatsbyImageData;

  return (
    <section className="o-hidden py-5 py-md-7 first-section">
      <div className="container">
        <div className="row align-items-center min-vh-40">
          <div className="col-md-7 col-lg-6 text-left mb-4 mb-lg-0">
            <span className="pb-2 pre-heading">
              UX Consulting
            </span>

            <h1 className="display-4 ">Pick the brain of a world-class UX team</h1> 

            <div className="my-4 lead">
              <div className="mb-1 badge badge-primary-2">How it works:</div>

              <ul className="list-unstyled">
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    1
                  </div>
                  <span className="lead">
                    <strong></strong>Book the consultation
                  </span>
                </li>
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    2
                    {/* <IconCheck className="injected-svg icon bg-primary-3" /> */}
                  </div>
                  <span className="lead">
                    <strong></strong>Fill the intake form
                  </span>
                </li>
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    3
                    {/* <IconCheck className="injected-svg icon bg-primary-3" /> */}
                  </div>
                  <span className="lead">
                    <strong></strong>Schedule the UX session
                  </span>
                </li>
              </ul>
            </div>

            <Link
              //to="https://buy.stripe.com/6oEcOk9YU1FL74Y000" //199 EUR
              to="https://book.stripe.com/fZe29G2wsgAFdtmfZ1"
              // to="/contact-us"
              // target="_blank"
              id="freeconsultation"
              className="btn btn-lg btn-primary btn-payment lead-cta"
            >
              Book UX Consultation<span>€ 149</span>
            </Link>
            <p className="small mt-2">Secure payment powered by Stripe</p>


            {/* <a
              className="mt-4 mt-md-0 ml-0 ml-md-2 btn btn-lg btn-outlined text-primary button lead-cta"
              id="freeconsultation"
              target="_blank"
              href="/start-from-scratch"
            >
              Start from scratch
            </a> */}
          </div>
          <div
            className="d-none d-md-block col-md-5 text-center"
            style={{ position: "relative" }}
          >
            <div className="min-vw-50">
              <GatsbyImage
                alt={"ImageApp"}
                style={{ maxWidth: 800 }}
                image={ImageHero}
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionWhyUXConsulting =({data}) => {
  const ImageIndustries = data.ImageIndustries.childImageSharp.gatsbyImageData;

  return (
    <section className="py-4 pt-xl-7 layer-4">
      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1 top"
          style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
        >
          <DecorationDots6 className="bg-yellow" />
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">

          <div className="col-md-6 offset-md-6">
            <h2 className="h1 mb-4">
            Why get a UX consultation
            </h2>
          </div>

          <div className="col-md-6 mb-4">
            <GatsbyImage
              image={ImageIndustries}
              alt="Industries"
              className="col-md-11"
              loading={"lazy"} />
          </div>

          <div className="col-md-6">
            
            {/*<p className="lead">
                We have revolutionised the performance of multiple successful apps,
                including:
              </p>*/}

            <ul className="list-unstyled mb-2">
              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>Boost your product’s UX</strong>
                  <br/>Start a new project on the right foot or get a professional opinion on an existing one
                </span>
              </li>
              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>UX Guidance</strong>
                  <br/>Avoid the costly mistakes of poor prioritization and having to redo things again.
                </span>
              </li>
              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>Consulting Versatility</strong>
                  <br/>Get access to a wide range of professionals whose expertise spans from UX research to UI best practices.
                </span>
              </li>
            </ul>
            {/* <p className="text-align-left">
              <Link
              to="https://buy.stripe.com/6oEcOk9YU1FL74Y000"
              target="_blank"
              id="freeconsultation"
              className="btn btn-lg btn-primary lead-cta"
              >
                Get closer to your goals
              </Link>
            </p> */}
          </div>
        </div>
      </div>
      {/* <div className="divider bg-primary-alt">
          <Divider1 className="" />
        </div> */}
    </section>
  );
};

const SectionOurImpact = ({ data }) => {
  const ImageOurProcess = data.ImageOurProcess.childImageSharp.gatsbyImageData;

  return (
    <section className="o-hidden py-4 pt-xl-7">
      <div className="decoration-wrapper d-none d-lg-block">
        <div className="decoration scale-1" style={{ top: 160, right: "12%" }}>
          <DecorationLine6 className="bg-primary" />
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center text-left text-md-center mb-2">
          <div className="col-xl-8 col-lg-9">
            <h2 className="display-4 mx-xl-5">Results we’ve helped our clients achieve</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 order-1 order-md-0">

            <ul className="list-unstyled mb-2">
              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>$114 ROI for every 1$</strong>
                  <br/>We’re sure we measure up because we measure. 
                  We’ve helped many clients get the most out of UX
                    
                </span>
              </li>

              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>57% increase in sales</strong>
                  <br/>We’re sure you’ve put the heart and soul into your product. 
                  Let us make sure it’s a pleasure to use.
                </span>
              </li>

              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>300% reduced costs on dev & support</strong>
                  <br/>You don’t need many support representatives or countless iterations if the design does the talking.
                </span>
              </li>

              <li className="d-flex py-3">
                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <span className="lead mt-n1">
                  <strong>78% Conversion Increase</strong>
                  <br/>Let your product do the selling. We’re here to guide you through every step of the way.
                </span>
              </li>
            </ul>

            <p className="text-align-left">
              <Link
              //to="https://buy.stripe.com/6oEcOk9YU1FL74Y000" //199 EUR
              to="https://book.stripe.com/fZe29G2wsgAFdtmfZ1"
              // to="/contact-us"
              target="_blank"
              id="freeconsultation"
              className="btn btn-lg btn-primary btn-payment lead-cta"
              >
                Book an impactful UX session<span>€ 149</span>
              </Link>
              <p className="small mt-2">Secure payment powered by Stripe</p>
            </p>  

          </div>

          <div className="col-md-6 d-flex align-items-center order-0 order-md-1 mb-4 mb-md-0">
            <GatsbyImage image={ImageOurProcess} alt="Our Process" style={{ flexGrow: 1 }} loading={"lazy"} />
          </div>

        </div>

        {/*
          <div className="col-md-6 order-1 order-md-0">
            <div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">$114 ROI for every 1$</h5>
                  <p>
                  We’re sure we measure up because we measure. 
                  We’ve helped numerous businesses get the most out of UX.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">57% increase in sales</h5>
                  <p>
                  We’re sure you’ve put the heart and soul into your product. 
                  Let us make sure it’s a pleasure to use.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">300% reduced costs on dev & support</h5>
                  <p>
                  You don’t need many support representatives, if the design does the talking. 
                  Neither do you need countless iterations if the process is set-up right.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">78% Conversion Increase</h5>
                  <p>
                  Let your product do the selling. We’re here to guide you through every step of the way.
                  </p>
                </div>
              </div>

            </div>
          </div>

        
        */}

      </div>
    </section>
  );
};


const TestimonialBlock = ({ data }) => {
  const thinkpricingAvatar = data.thinkpricingAvatar.childImageSharp.gatsbyImageData;
  const qoloAvatar = data.qoloAvatar.childImageSharp.gatsbyImageData;
  const agentAvatar = data.agentAvatar.childImageSharp.gatsbyImageData;

  return (
    <Testimonials
      className="py-4 py-md-6 layer-4 testimonial-compact"
      title="What Our Customers Say"
      testimonials={[
        {
          content: `The final UX design meets all our requirements and looks great.
                                    The team is efficient and to the point, impressively able to understand design
                                    needs and integrate feedback.`,
          imageFixed: thinkpricingAvatar,
          logo: (
            <ThinkpricingReview
              className="align-items-left"
              style={{ width: "178px" }}
            />
          )
        },
        {
          content: `The MVP received positive feedback from the internal team.
                                    Adam Fard UX Studio creates high-quality designs that meet the needs of the client.
                                    The team is hard-working, innovative, and driven.`,
          imageFixed: qoloAvatar,
          logo: (
            <QoloReview
              className="align-items-left"
              style={{ width: "148px" }}
            />
          )
        },
        {
          content: `Adam Fard UX Studio gave us the hands-on feeling of
                                    a small shop and the quality and professionalism of a big shop.`,
          imageFixed: agentAvatar,
          logo: (
            <AgentadvanceReview
              className="align-items-left"
              style={{ width: "143px" }}
            />
          )
        }
      ]}
    />
  );
};

const SectionFaq = ({ data }) => {
  const projectsThinkpricingImg =
    data.projectsThinkpricingImg.childImageSharp.gatsbyImageData;
  const projectsKinteractImg =
    data.projectsKinteractImg.childImageSharp.gatsbyImageData;
  const projectsQoloImg = data.projectsQoloImg.childImageSharp.gatsbyImageData;

  const toggleAccordion = e => {
    const panel = e.target;
    const panelAccordionContent = panel.querySelector(`.accordion-content`);
    const panelIcon = panel.querySelector(".icon");
  
    if (panelAccordionContent.className.includes("collapse")) {
      panelAccordionContent.classList.remove("collapse");
      panelIcon.classList.add("icon-rotate");
    } else {
      panelAccordionContent.classList.add("collapse");
      panelIcon.classList.remove("icon-rotate");
    }
  };
  
  return (
    <section className="o-hidden bg-primary-alt has-divider">
        <div className="divider bg-white">
          <Divider1 className="bg-primary-alt" />
        </div>
      <div className="container py-3">
          <div className="row mt-md-4 justify-content-center">
            <div className="col-12 d-flex flex-column text-center">
              <article
                id="article-content"
                className="article article-blog"
                style={{ zIndex: 9993 }}
              >
                <h2 className="display-4 text-center">FAQ</h2>

                <div
                  onClick={toggleAccordion}
                  style={{ cursor: 'pointer' }}
                  id="panel-1"
                  className="card card-sm card-body card--no-hover text-left"
                >
                  <div
                    style={{ pointerEvents: 'none' }}
                    className="accordion-panel-title"
                    aria-controls="panel-1"
                  >
                    <p aria-controls="panel-1">
                      <strong aria-controls="panel-1">
                        {" "}
                        How much does a consultation cost?{" "}
                      </strong>
                    </p>
                    <DatoAsset
                      className="icon"
                      src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                      aria-controls="panel-1"
                    />
                  </div>
                  <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                    <div className="pt-3">
                      <p>
                      We charge EUR 149 (~ USD 159) for an hour-long consultation.
                      </p>
                    </div>
                  </div>
                </div>


                <div
                  onClick={toggleAccordion}
                  style={{ cursor: 'pointer' }}
                  id="panel-1"
                  className="card card-sm card-body card--no-hover text-left"
                >
                  <div
                    style={{ pointerEvents: 'none' }}
                    className="accordion-panel-title"
                    aria-controls="panel-1"
                  >
                    <p aria-controls="panel-1">
                      <strong aria-controls="panel-1">
                        {" "}
                        Can I book UX consulting on a regular basis?{" "}
                      </strong>
                    </p>
                    <DatoAsset
                      className="icon"
                      src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                      aria-controls="panel-1"
                    />
                  </div>
                  <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                    <div className="pt-3">
                      <p>
                      Sure! We can arrange the format that’s convenient for you. 
                      However, it would make more sense to foster an ongoing cooperation, 
                      as it would decrease the hourly rate.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={toggleAccordion}
                  style={{ cursor: 'pointer' }}
                  id="panel-1"
                  className="card card-sm card-body card--no-hover text-left"
                >
                  <div
                    style={{ pointerEvents: 'none' }}
                    className="accordion-panel-title"
                    aria-controls="panel-1"
                  >
                    <p aria-controls="panel-1">
                      <strong aria-controls="panel-1">
                        {" "}
                        Can you also do the design or research on top of consulting?{" "}
                      </strong>
                    </p>
                    <DatoAsset
                      className="icon"
                      src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                      aria-controls="panel-1"
                    />
                  </div>
                  <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                    <div className="pt-3">
                      <p>
                        Yes, we can. Though it should be noted that a different rate may apply.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={toggleAccordion}
                  style={{ cursor: 'pointer' }}
                  id="panel-1"
                  className="card card-sm card-body card--no-hover text-left"
                >
                  <div
                    style={{ pointerEvents: 'none' }}
                    className="accordion-panel-title"
                    aria-controls="panel-1"
                  >
                    <p aria-controls="panel-1">
                      <strong aria-controls="panel-1">
                        {" "}
                        How do I know I need a UX consultation?{" "}
                      </strong>
                    </p>
                    <DatoAsset
                      className="icon"
                      src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                      aria-controls="panel-1"
                    />
                  </div>
                  <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                    <div className="pt-3">
                      <p>
                        Usually, you would need a user and product experience consultation wherever there’s a short-term gap in UX expertise that needs to be addressed.
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            
          </div>
      </div>

    </section>
  );
};

const SectionTrustedCompanies = () => (
  <section className="py-4 py-md-6">
    <div className="container">
      <div className="row text-center mb-0">
        <div className="col-12 col-md-8 mx-auto mb-4">
          {/* <div className="mb-1 badge badge-primary-2"></div> */}
          {/* <h2 className="h2 mb-2">Contact Us </h2> */}
          {/* <p clss="lead text-align-center">
            As a special Winter deal, <strong>we charge EUR 199 (~ USD 225) for 1 hour of consultation.</strong> The price will go to EUR 300 (~ USD 340) after February 28th, so don't hesitate 🙂
          </p> */}
          
          {/* <p className="text-align-center">
            <Link
            // to="https://buy.stripe.com/6oEcOk9YU1FL74Y000"
            to="/contact-us"
            // target="_blank"
            id="freeconsultation"
            className="btn btn-lg btn-primary lead-cta"
            >
              Get in Touch
            </Link>
          </p> */}
        </div>
      </div>

      <div className="row text-center mb-3">
        <div className="col">
          <small className="">Trusted by companies like</small>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
            <li>
              <a target="_blank" href="/projects/ux-design-samsung-app-testing-platform"><LogoSamsung className="" /></a>
            </li>
            <li>
              <a target="_blank" href="/contact-us"><LogoTmobile className="" /></a>
            </li>
            <li>
              <a target="_blank" href="/projects/edtech-saas-design"><LogoKinteract className="" /></a>
            </li>
            <li>
              <a target="_blank" href="/projects/saas-b2b-platform"><LogoThinkpricingGrey className="" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

class SectionIndustries extends Component {
  state = {
    activeTab: "Finance",
  };
  setNewActiveTab = (tabName) => {
    this.setState({
      activeTab: tabName,
    });
  };

  render() {
    const IndustriesTabs = [
      {
        name: "Finance",
        image: this.props.data.fintechImg.childImageSharp.gatsbyImageData,
        title: "Creating a more intuitive FinTech platform by embracing innovative design.",
        caption:
          "We’ve designed, adapted and enhanced data visualization, e-wallets, trading apps, credit scoring tools and more",
        link: "https://adamfard.com/fintech-design",
        cta: "Fintech Design"
      },
      {
        name: "SaaS",
        image: this.props.data.saasImg.childImageSharp.gatsbyImageData,
        title: "Achieving maximum user-engagement and satisfaction",
        caption:
          "SaaS (Software as a Service) is a thriving industry. Subscription-based models offer customers a simple, hassle-free service — but good UX design is crucial to maximize satisfaction. We’ve helped SaaS companies on all scales, from startups to established brands.",
        link: "https://adamfard.com/saas-design",
        cta: "SaaS Design"
      },
      {
        name: "Education",
        image: this.props.data.educationImg.childImageSharp.gatsbyImageData,
        title: "Taking online education tools to the next level",
        caption:
          "Edtech is one of the most important industries in the digital age. We help educators deliver cutting-edge courses and resources with a market-leading user experience",
        link: "https://adamfard.com/edtech-design",
        cta: "Edtech Design"
      },
      {
        name: "IT & Communication",
        image: this.props.data.communicationImg.childImageSharp.gatsbyImageData,
        title: "Making IT & communications simpler and more accessible",
        caption:
          "IT and communication platforms can be complex — or downright confusing — for professionals at all levels. Our work in this industry centers on creating smoother, simpler UX design for a more engaging experience.",
        link: "https://adamfard.com/contact-us",
        cta: "Request more info"
      },
      {
        name: "Healthcare & Wellness",
        image: this.props.data.healthcareImg.childImageSharp.gatsbyImageData,
        title: "Bringing crucial knowledge and assistance to users",
        caption:
          "Everyone deserves access to informative healthcare & wellness resources, but poor UX design can leave people without the answers they need. We’ve transformed apps and online platforms for a stronger, more fulfilling experience.",
        link: "https://adamfard.com/contact-us",
        cta: "Request more info"
      },
    ];

    return (
      <section className="position-relative sixth-section px-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center col-md-12 col-lg-10">
              <h2> We also work with multiple industries </h2>
              <div className="d-flex mb-5 justify-content-center">
                <ul className="nav lead custom-tabs custom-tabs--scroll">
                  {IndustriesTabs.map(({ name }, index) => (
                    <li
                      className="nav-item"
                      key={index}
                      onClick={() => this.setNewActiveTab(name)}
                    >
                      <span
                        className={`nav-link pl-0 ${
                          this.state.activeTab === name ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {IndustriesTabs.map((info, index) => (
            <div
              key={index}
              className={`tab-animation ${
                info.name === this.state.activeTab
                  ? "active animated fadeIn"
                  : ""
              }`}
            >
              <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                  <GatsbyImage image={info.image} loading="lazy" />
                </div>
                <div className="col-12 col-lg-6 text-left">
                  <h3 className="h3">{info.title}</h3>
                  <p className="my-4 lead">{info.caption}</p>
                  <a
                    href={info.link}
                    className="mt-2 mb-2 mb-md-0 hover-arrow text-primary lead"
                  >
                    {info.cta}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}


const SectionTopUXAgency = ({ data }) => {
  const LogoClutch = data.LogoClutch.childImageSharp.gatsbyImageData;
  const LogoGoodfirms = data.LogoGoodfirms.childImageSharp.gatsbyImageData;
  const LogoDAN = data.LogoDAN.childImageSharp.gatsbyImageData;

  return (
    <section className="pt-3 seventh-section">
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-md-8 text-left">
            <h2 className="display-4">Our Hall of Fame</h2>
            <p className="lead">
              Our clients aren’t the only ones who recognize our expertise
              though. In just four years of getting the job done as a UX design
              agency, we’ve earned our well-deserved spot!
            </p>
          </div>
        </div>
        <div className="row mt-4 d-flex">
          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://clutch.co/profile/adam-fard-ux-studio"
              target="_blank"
              className="card card-body"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoClutch}
                  alt=""
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  height={160}
                  style={{ height: "160px" }}
                  loading={"lazy"}
                />
              </div>

              <p className="mb-0" style={{ color: "#17313B" }}>
                <strong>
                  Clutch <span style={{ color: "#ff3d2e" }}> ★★★★★</span>
                </strong>
              </p>
              <h4>Top UX Agency & B2B company</h4>
              <p className="lead">
                Clutch is an industry-standard data-driven B2B ranking web site
                for digital agencies and UX studios.
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://www.goodfirms.co/company/adam-fard-studio/services"
              target="_blank"
              className="card card-body"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoGoodfirms}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>
              <p className="mb-0" style={{ color: "#4385F4" }}>
                <strong>
                  GoodFirms <span style={{ color: "#ffba00" }}> ★★★★★</span>
                </strong>
              </p>
              <h4>Top App Design Company</h4>
              <p className="lead">
                GoodFirms is a review website that ranks top software and UX
                design agencies.
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://digitalagencynetwork.com/agencies/berlin/"
              target="_blank"
              className="card card-body"
              style={{ height: "calc(100% - 1.5rem)" }}
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={LogoDAN}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"}
                />
              </div>
              <p className="mb-0" style={{ color: "#F3188D" }}>
                <strong>Digital Agency Network</strong>
              </p>
              <h4>Featured UX Studio</h4>
              <p className="lead">
                Digital Agency Network is an all-in-one platform to discover the
                best digital design and marketing agencies in the world.
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionOurBook = ({ data }) => {
  const ImageBook = data.ImageBook.childImageSharp.gatsbyImageData;

  return (
    <section className="text-light py-2 py-md-1 o-hidden bg-primary eighth-section">
      <div className="container align-items-center text-center text-md-left bg-primary">
        <div className="row py-6 align-items-center">
          <div className="col-12 col-md-6 text-center pb-4 pb-md-0">
            <GatsbyImage
              image={ImageBook}
              alt="The Art of Launching a Product For Startups Cover"
              className=""
              loading={"lazy"}
            />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <div>
              <span className="badge badge-primary-2 mb-2 lead">
                Handbook for Startups & Product Owners
              </span>
              <h2 className="h1">The Art of Launching a Product</h2>
              <p className="lead">
                Learn step-by-step, how to perfectly plan and execute the launch
                of your digital product.
              </p>
              <a
                href=" https://adamfard.com/download-ux-resources/launch-digital-product"
                className="btn btn-lg btn-white mt-3"
              >
                Download Free Book
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionBlog = ({ FeaturedArticlesData }) => (
  <section className="o-hidden pb-0 py-md-4 ninth-section">
    <div className="container">
      <div className="decoration-container w-100 position-relative d-none d-md-block">
        <div className="decoration-wrapper decoration-wrapper-2 w-100 position-relative">
          <div className="decoration position-relative w-100">
            <DecorationBlob3 className="bg-primary-alt decoration-blob-3" />
          </div>
        </div>
      </div>
      <div className="row pl-3 pl-md-0">
        <h2 className="display-4 mb-2">We like to educate our readers</h2>
      </div>
      <div className="row pl-3 pl-md-0 lead">
        <p className="mr-1">
          Enjoy a few our of recent articles - fresh out of the oven
        </p>
        <Link to="/blog" target="_blank" className="hover-arrow">
          Read All UX Blogs
        </Link>
      </div>
      <div className="row mt-4">
        {FeaturedArticlesData.map(({ node }) => (
          <div className="col-md-6 col-lg-4" key={node.id}>
            <CardBlog cardData={node} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default UxConsultingPage;

export const query = graphql`
  query uxConsultingQuery {
    ImageGrow: file(relativePath: { eq: "photos/expertise.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageIndustries: file(
      relativePath: { eq: "photos/why-ux-consulting.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageOurProcess: file(
      relativePath: { eq: "photos/impacts-ux.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageBook: file(
      relativePath: { eq: "photos/The-Art-of-Launching-a-Digital-Product.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ImageHero: file(relativePath: { eq: "photos/ux-consulting-service-.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1800)
      }
    }
    LogoClutch: file(
      relativePath: { eq: "logos/brand/Top_B2B_Western_Europe.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    LogoGoodfirms: file(
      relativePath: { eq: "logos/brand/mobile-app-design-companies.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    LogoDAN: file(relativePath: { eq: "logos/brand/featured-ux-studio.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    agentAvatar: file(relativePath: { eq: "photos/agent-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    thinkpricingAvatar: file(relativePath: { eq: "photos/think-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    qoloAvatar: file(relativePath: { eq: "photos/qolo-avatar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: FIXED)
      }
    }
    projectsThinkpricingImg: file(
      relativePath: { eq: "photos/home-projects-thinkpricing.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    projectsKinteractImg: file(
      relativePath: { eq: "photos/home-projects-kinteract.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    projectsQoloImg: file(
      relativePath: { eq: "photos/home-projects-qolo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    fintechImg: file(relativePath: { eq: "photos/Fintech.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    healthcareImg: file(relativePath: { eq: "photos/Healthcare.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    saasImg: file(relativePath: { eq: "photos/SaaS.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    communicationImg: file(relativePath: { eq: "photos/IT-Communication.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    educationImg: file(relativePath: { eq: "photos/Education.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    itImg: file(relativePath: { eq: "photos/home-it.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    datoCmsPage(slug: {eq: "ux-consulting"}) {
      seo {
          title
          description
          twitterCard
          image {
              url
          }
      }
    }
    featuredArticles: allDatoCmsArticle(
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 544, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
